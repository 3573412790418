import React from 'react';
import './css/ttt.css'; // 引入样式文件
import { $tv } from 'react-tv-focusable';

const FocusTest = () => {

  window.$tv = $tv;
      return (
        <div className="demo">
          <div className="item" focusable="">Item 1</div>
          <div className="item" focusable="">Item 2</div>
          <div className="item" focusable="">Item 3</div>
      </div>
      );
    };

export default FocusTest;

