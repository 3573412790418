import React, { useState, useEffect, useRef, useCallback } from 'react';
import HorizontalList from './HorizontalList';
import HorizontalList2 from './HorizontalList2';
import { debounce } from 'lodash';

const HorizontalListContainer = ({ title, items, rowIndexModel, onRowIndexChange, listIndex, focusedVerticalIndex, setVerticalFocusedIndex }) => {
  
  // 更新 posterRef 的值
  useEffect(() => {
    console.log("focusedVerticalIndex");
  }, [focusedVerticalIndex]);


  return (
    <div>
      <HorizontalList2
        title={title}
        items={items}
        rowIndexModel={rowIndexModel}
        onRowIndexChange={onRowIndexChange}
        listIndex={listIndex}
        focusedVerticalIndex={focusedVerticalIndex}
        setVerticalFocusedIndex={setVerticalFocusedIndex}
      />
    </div>
  );
};

export default HorizontalListContainer;