import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import Hls from 'hls.js';

const HLSPlayer = forwardRef(({ url, isLL, style, onPlayerReady, isVOD }, ref) => { 

  useImperativeHandle(ref, () => ({
    adjustPlaybackTime: (seconds) => {
      if (videoRef.current) {
        console.log("adjustPlaybackTime");
        let newTime = videoRef.current.currentTime + seconds;
        videoRef.current.currentTime = Math.max(0, newTime); // 防止时间小于0
      }
    },
    changePlaybackRate: (rate) => {
      console.log("rate : " + rate);
      // videoElement.playbackRate = rate;
      // videoElement.playbackRate.pause();
      // videoElement.playbackRate.play();
      
    },
    getPlaybackRate: () => {
      return videoRef.current.playbackRate;
      //return videoRef.current ? videoRef.current.playbackRate : 1;
    },
  }));


    const pseudoFullscreenStyle = {
        position: 'absolute', // 或 'absolute'
        top: 0,
        left: 0,
        // width: '100%',
        // height: '100%',
        zIndex: 9999,
      };

    const videoRef = useRef(null);

    useEffect(() => {
      let hls;
  
      const initializePlayer = () => {
        if (Hls.isSupported()) {
          const video = videoRef.current;
            if(isLL){
                hls = new Hls({
                    debug: true, // 是否启用调试模式
                    maxBufferLength: 5, // 最大缓冲长度，单位为秒，默认值为30
                    maxBufferSize: 60 * 1000 * 1000, // 最大缓冲区大小，单位为字节，默认值为60MB
                    liveSyncDurationCount: 1, // 直播同步时长统计的片段数量，默认值为3
                    liveMaxLatencyDurationCount: 2, // 直播最大延迟时长统计的片段数量，默认值为5
                    lowLatencyMode: true, // 是否启用低延迟模式
                    lowLatencyModeMinDrift: 0.2, // 低延迟模式最小漂移值，单位为秒，默认值为0.2
                    lowLatencyModeMaxDrift: 0.5 // 低延迟模式最大漂移值，单位为秒，默认值为0.5
                  });
                  hls.lowLatencyMode = true;
            }else{
                hls = new Hls();
            }
        
          // EME related event listeners
        hls.on(Hls.Events.KEY_LOADING, (event, data) => {
            // Handle key loading event
            console.log('Key loading:', data);
          });
  
          hls.on(Hls.Events.KEY_LOADED, (event, data) => {
            // Handle key loaded event
            console.log('Key loaded:', data);
          });
  
          hls.on(Hls.Events.KEY_ERROR, (event, data) => {
            // Handle key error event
            console.error('Key error:', data);
          });
    
  
          
        
          hls.loadSource(url);
           // Load the DRM license
            // hls.loadSource({
            //     src: 'https://wowzatest-hieventtrial2.cdn.hinet.net/vod_tl_proxy-wv/_definst_/smil:zztest/bbb_sunflower_10min_v2/bbb_sunflower_10min.smil/manifest.mpd',
            //     type: 'application/x-mpegURL', // 设置为 HLS 流
            //     keySystems: {
            //     'com.widevine.alpha': {
            //         url: 'https://tlmrlab-hamivideo2.cdn.hinet.net/getLicense?sessionId=a6aed39b0742b445352552cf0cbe6853&deviceIp=212.82.115.16&region=chtn&chsm=ab1bda49a4382830d759c8184271e80b'
            //     }
            //     }
            // });
  
          hls.attachMedia(video);
  
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            video.play();
          });
  
          // Handle potential hls.js errors
          hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // Try to recover network error
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  // Try to recover media error
                  hls.recoverMediaError();
                  break;
                default:
                  // No recovery possible
                  hls.destroy();
                  break;
              }
            }
          });
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          // Use native HLS support if available
          videoRef.current.src = url;
          videoRef.current.addEventListener('canplay', () => {
            videoRef.current.play();
          });
        }
      };
  
      initializePlayer();

      // 设置全屏样式
    if (videoRef.current) {
      videoRef.current.style.width = '100%';
    }
  
      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    }, [url]);
  
    return (
        <div style={{ ...pseudoFullscreenStyle, ...style, background: 'black' }}>
        <video controls ref={videoRef} style={{ width: '100%', height: '100%' }} />
      </div>
    );
  });
  
export default HLSPlayer;