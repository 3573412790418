// UIInfoModel.js
class UIInfoModel {
    constructor(type, title, elements, asdf) {
      this.type = type;
      this.title =title;
      this.elements = elements;
      this.asdf = asdf;
    }
  }
  
  export default UIInfoModel;