// poseterModel.js
class poseterModel {
    constructor(productId, title, subTitle, descText, imageId, previewUrl) {
      this.productId = productId;
      this.title = title;
      this.subTitle = subTitle;
      this.descText = descText;
      this.imageId = imageId;
      this.previewUrl = previewUrl;
    }
  }
  
  export default poseterModel;