import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ChoosePage from './ChoosePage';
import FocusTest from './FocusTest';
import VODDetailPage2 from './VODDetailPage2';
import VODDetailPage3 from './VODDetailPage3';
import VODDetailPage4 from './VODDetailPage4';
import VODDetailPage5 from './VODDetailPage5';
import ShakaHLSLive from './ShakaHLSLive';
import ShakaHLSLLLive from './ShakaHLSLLLive';

import reportWebVitals from './reportWebVitals';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { FocusProvider } from './FocusContext'; // 路徑根據你的檔案結構調整
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

function ShowBasedOnRoute({ path, children }) {
  const location = useLocation();
  return location.pathname === path ? children : null;
}


const root = ReactDOM.createRoot(document.getElementById('root'));
fetch('cht.hami.poc', {
  headers: {
    'bypass-tunnel-reminder': 'true'
  }
})

// console.log('initShakaPlayer');
// <ToastContainer /> {/* 将 ToastContainer 放置在根组件中 */}
console.log('root.render');
root.render(
  <Router>
     
  <div style={{ backgroundColor: 'black'}}>
    <ShowBasedOnRoute path="/">
    
      <ChoosePage />
      
    </ShowBasedOnRoute>
    <ShowBasedOnRoute path="/App">
   
      <App />
    </ShowBasedOnRoute>
    <ShowBasedOnRoute path="/VODDetailPage2">
      <VODDetailPage2 />
      <ToastContainer /> {/* 将 ToastContainer 放置在根组件中 */}
    </ShowBasedOnRoute>
    <ShowBasedOnRoute path="/VODDetailPage3">
      <VODDetailPage3 />
    </ShowBasedOnRoute>
    <ShowBasedOnRoute path="/VODDetailPage4">
      <VODDetailPage4 />
      <ToastContainer /> {/* 将 ToastContainer 放置在根组件中 */}
    </ShowBasedOnRoute>
    <ShowBasedOnRoute path="/VODDetailPage5">
      <VODDetailPage5 />
    </ShowBasedOnRoute>
    <ShowBasedOnRoute path="/ShakaHLSLive">
      <ShakaHLSLive />
    </ShowBasedOnRoute>
    <ShowBasedOnRoute path="/ShakaHLSLLLive">
      <ShakaHLSLLLive />
    </ShowBasedOnRoute>
    {/* 其他路由和组件 */}
  </div>
</Router>
  // <Router>
  //   <Switch>
  //     <Route exact path="/" component={ChoosePage} />
  //     <Route path="/App" component={App} />
  //     <Route path="/VODDetailPage2" component={VODDetailPage2} />
  //     <Route path="/VODDetailPage3" component={VODDetailPage3} />
  //     <Route path="/VODDetailPage4" component={VODDetailPage4} />
  //   </Switch>
  // </Router>
  // <React.StrictMode>

  //   <ChoosePage />
  // </React.StrictMode>
);


// root.render(
//   <React.StrictMode>
    
//     <App />
//     <ToastContainer /> {/* 将 ToastContainer 放置在根组件中 */}
//   </React.StrictMode>
// );
//<ToastContainer /> {/* 将 ToastContainer 保持在根组件中 */}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
