// App.js
//import React from 'react';
import HorizontalListContainer from './HorizontalListContainer';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

//import getUILayoutByIdModel from './Model/getUILayoutByIdModel';
import getUILayoutByIdModel from './Ｍodel/getUILayoutByIdModel';

import DetailPage from './DetailPage';

import VODDetailPage from './VODDetailPage';
import VODDetailPage2 from './VODDetailPage2';
import VODDetailPage3 from './VODDetailPage3';
import VODDetailPage4 from './VODDetailPage4';

import getUILayoutByIdJsonData from './getUILayoutById.json';
import UIInfoModel from './Ｍodel/UIInfoModel';
import posterModel from './Ｍodel/posterModel';
import RowIndexModel from './Ｍodel/RowIndexModel';

import { setGlobalVariable, getGlobalVariable } from './manager/VerticalManager';
import { sethashMapByRowIndex, gethashMapByRowIndex, setIndexhashMapByRowIndex, getIndexhashMapByRowIndex } from './manager/HorizonManager';


import { FixedSizeList as List } from 'react-window';
const App = function App() {
//const App = () => {
  const [hashMap, setuiinfo] = useState([]);
  const [hashMapByRowIndex, setuiRowIndex] = useState([]);
  const [UIInfoObjectArray, setUIArray] = useState([]);
  
  const history = useHistory(); // 正确使用 useHistory
  
  const listRef = useRef(); // 用于引用 List 组件
  const [focusedVerticalIndex, setVerticalFocusedIndex] = useState(getGlobalVariable()); // 當前聚焦的物件索引

  //const location = useLocation();
  //const { onRowIndexChange: posterFromLink } = location.state || {};

  //const posterFromLocation = useLocation().state?.onRowIndexChange;
  //const onRowIndexChange = posterFromLink || posterFromLocation;
  const containerHeight = window.innerHeight * 0.65; // 视口高度的 60%

  useEffect(() => {
    console.log('UIInfoObjectArray test : ' + gethashMapByRowIndex().length);
    
    console.log("gethashMapByRowIndex().length : " + gethashMapByRowIndex().length);
    console.log('setIndexhashMapByRowIndex Done');
    if(gethashMapByRowIndex().length > 0){
      console.log('hashMapByRowIndex[index].lastVisibleIndex : ' + gethashMapByRowIndex()[0].lastVisibleIndex);
      console.log('hashMapByRowIndex[index].targetIndex : ' + gethashMapByRowIndex()[0].targetIndex);
    }
        
  }, []);
  
  useEffect(() => {
    try {
      
      // 映射到 User 模型
      const getUILayoutByIdObject = new getUILayoutByIdModel(getUILayoutByIdJsonData.returnCode, getUILayoutByIdJsonData.UIInfo);
      setUIArray(getUILayoutByIdObject.UIInfo.map((UIInfoData) => new UIInfoModel(UIInfoData.type, UIInfoData.title, UIInfoData.elements)));

      // 在 UIInfoModel 构造函数中正确传递 rowIndexModel 参数
      const UIArray = [];
      getUILayoutByIdObject.UIInfo.forEach((UIInfoData, index) => {
        if (UIInfoData.title.length > 0) {
          const rowIndexModel = new RowIndexModel(index, 0, 5, 0); // 创建 RowIndexModel 对象
          const uiInfoModel = new UIInfoModel(UIInfoData.type, UIInfoData.title, UIInfoData.elements, rowIndexModel); // 将 rowIndexModel 传递给 UIInfoModel 构造函数
          UIArray.push(uiInfoModel);
          
        }
      });
      console.log('UIArray.length : ' + UIArray.length);
      setUIArray(UIArray);

      const UIInfoObjectArray = getUILayoutByIdObject.UIInfo.map((UIInfoData) => new UIInfoModel(UIInfoData.type, UIInfoData.title, UIInfoData.elements));
      const hashMap = {};
      

      // 初始化 hashMap
      UIInfoObjectArray.forEach((UIInfoData) => {
        if(UIInfoData.title.length > 0){
          hashMap[UIInfoData.type] = UIInfoData.elements.map((elementData) => new posterModel(elementData.productId, elementData.title, elementData.subTitle, elementData.descText, elementData.imageId, elementData.previewUrl));
        }
        

      });
      setuiinfo(hashMap);
  
      
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }, [getUILayoutByIdJsonData.text]);

  useEffect(() => {
    console.log('UIInfoObjectArray : ' + gethashMapByRowIndex().length);
    if(gethashMapByRowIndex().length == 0){
      if(UIInfoObjectArray.length > 0){
        const hashMapByRowIndex = [];
        //用迴圈將代辦事項的內容一個個放進空陣列中
        // 初始化 hashMapByRowIndex
        for (let i = 0; i < UIInfoObjectArray.length; i++) {
          hashMapByRowIndex.push(new RowIndexModel(i, 0, 5, 0));
        }
        console.log('sethashMapByRowIndex');
        sethashMapByRowIndex(hashMapByRowIndex);
        console.log('gethashMapByRowIndex : ' + gethashMapByRowIndex().length);
        setuiRowIndex(hashMapByRowIndex);
      }
    }
    
    
  }, [UIInfoObjectArray]);

  // 更新 posterRef 的值
  useEffect(() => {
    smoothScrollTo(getGlobalVariable() * containerHeight);
  }, []);

  
  // 调用出 HashMap 中的特定项的函数，使用字符串键
  const getItemByKey = (itemKey) => {
    return hashMap[itemKey] != null ? hashMap[itemKey]:[];
  };

    // 调用出 HashMap 中的特定项的函数，使用字符串键
    const getItem = (elements) => {
      console.log("getItem");
      return elements.map((elementData) => new posterModel(elementData.productId, elementData.title, elementData.subTitle, elementData.descText, elementData.imageId, elementData.previewUrl));
    };
    useEffect(() => {
      const handleKeyDown = (event) => {
        let newIndex = getGlobalVariable(); // 获取当前焦点索引
        switch (event.key) {
          
          case 'Backspace':
            history.goBack(); // 调用 goBack 方法返回到上一页
            break;
            case 'XF86Back':
              history.goBack(); // 调用 goBack 方法返回到上一页
            break;
          case 'ArrowUp':
            newIndex = Math.max(0, newIndex - 1); // 确保索引不会小于0
            break;
          case 'ArrowDown':
            newIndex = Math.min(UIInfoObjectArray.length - 1, newIndex + 1); // 确保索引不会超过数组长度
            break;
          // 其他按键事件...
        }
    
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          console.log("newIndex : " + newIndex);
          setVerticalFocusedIndex(newIndex); // 更新焦点索引
          setGlobalVariable(newIndex);
          event.preventDefault(); // 防止默认的滚动行为
    
          // 计算新的滚动目标位置
          const targetPosition = newIndex * containerHeight;
          smoothScrollTo(targetPosition);
        }
      };
    
      window.addEventListener('keydown', handleKeyDown);
    
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [focusedVerticalIndex, UIInfoObjectArray.length]); // 添加 focusedVerticalInde
   

  const handleChange = (listIndex, lastVisibleIndex, focusedhorizontalIndex) => {
    console.log('handleChange');
    console.log('listIndex : ' + listIndex + " lastVisibleIndex : " + lastVisibleIndex + " focusedhorizontalIndex : " + focusedhorizontalIndex);
    //onRowIndexChange();
    if(UIInfoObjectArray.length > 0){
      if(UIInfoObjectArray[listIndex].asdf != null){
        UIInfoObjectArray[listIndex].asdf.lastVisibleIndex = lastVisibleIndex;
        UIInfoObjectArray[listIndex].asdf.targetIndex = focusedhorizontalIndex;

        var value = getIndexhashMapByRowIndex(listIndex);
        if (value) {
          value.lastVisibleIndex = lastVisibleIndex;
          value.targetIndex = focusedhorizontalIndex;
          console.log('setIndexhashMapByRowIndex');
          setIndexhashMapByRowIndex(listIndex, value);
        }
      }
      
    }
    
    

    setUIArray(UIInfoObjectArray);
    
  };




  const divRef = useRef(null)

  useEffect(() => {
    // 添加事件监听器
    const div = divRef.current;
    if (div) {
      div.addEventListener('wheel', closeDefault, { passive: false });
    }
  
    // 返回的清理函数
    return () => {
      // 在尝试移除监听器前检查 div 是否非 null
      if (div) {
        div.removeEventListener('wheel', closeDefault, { passive: false });
      }
    };
  }, []);

//取消默认事件
function closeDefault(e) {
  if (e.deltaX !== 0) {
    e.preventDefault(); // 如果检测到水平滚动，则阻止默认行为
  }
  if (e.preventDefault) {
    e.preventDefault()  //取消事件的默认行为
    e.stopPropagation() //阻止冒泡
  } else {
    //兼容IE
    window.event.preventDefault = false //IE下取消事件的默认行为
    e.cancelBubble = true               //IE下阻止冒泡
  }
  return false
}

// useEffect(() => {
//   console.log('上下');
  
//   const targetPosition = focusedVerticalIndex * window.innerHeight * 0.64; // 假设每个项目的高度为35
//     smoothScrollTo(targetPosition);
//   //listRef.current.scrollToItem(focusedVerticalIndex, 'start'); // 将新焦点项滚动到顶部
// }, [focusedVerticalIndex]);

let isScrolling = false;

const smoothScrollTo = (targetPosition) => {
  console.log(`smoothScrollTo called with targetPosition: ${targetPosition}`);

  if (!divRef.current) {
    console.log('divRef.current is not available');
    return;
  }

  let currentScrollPosition = divRef.current.scrollTop;
  //console.log(`Current scroll position: ${currentScrollPosition}`);

  const scrollStep = () => {
    
    if (divRef.current) {
      currentScrollPosition += (targetPosition - currentScrollPosition) / 2;
    //console.log(`Updated scroll position: ${currentScrollPosition}`);

    if (Math.abs(targetPosition - currentScrollPosition) < 1) {
      divRef.current.scrollTop = targetPosition;
      //console.log(`Final scroll position set to: ${targetPosition}`);
      return;
    }
      divRef.current.scrollTop = currentScrollPosition;
    }
    requestAnimationFrame(scrollStep);
  };

  requestAnimationFrame(scrollStep);
};

return (
  <div ref={divRef} style={{ backgroundColor: 'black', color: 'white', height: window.innerHeight, overflowY: 'scroll' }}>
    {UIInfoObjectArray.map((UIInfoObject, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center', height: `${containerHeight}px`, overflow: 'scroll' }}>
        <HorizontalListContainer 
          title={UIInfoObject.title} 
          items={getItem(UIInfoObject.elements)} // 直接调用 getItem 函数
          rowIndexModel={UIInfoObject.asdf} 
          onRowIndexChange={handleChange} 
          listIndex={index} 
          focusedVerticalIndex={focusedVerticalIndex} 
          setVerticalFocusedIndex={setVerticalFocusedIndex}
        />
      </div>
    ))}
  </div>
);
};

export default App;