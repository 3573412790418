// manager.js
let verticalIndex = 0;

function setGlobalVariable(value) {
    verticalIndex = value;
}

function getGlobalVariable() {
    return verticalIndex;
}

export { setGlobalVariable, getGlobalVariable };
