import React, { useEffect, Suspense, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

//import VideoPlayer from './player/VideoPlayer';
const ShakaHLSLive = () => {

  const history = useHistory();
  const LazyShakaPlayer = React.lazy(() => import('./player/VideoPlayer'));



  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log('handleKeyDown : ' + event.key);
      if(event.key === 'Backspace' || event.key === 'XF86Back'){
        history.goBack(); // 调用 goBack 方法返回到上一页

      }
      
      
      //history.goBack(); // 调用 goBack 方法返回到上一页
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // 確保當 focusedIndex 改變時重新註冊鍵盤事件

  return (
    
    <div style={{ flex: '1.5', textAlign: 'center', paddingLeft: 10 }}>

    <Suspense >
    <LazyShakaPlayer src='https://mobileevent-hamivideo.cdn.hinet.net/live/pool/hamitv-live166/llhls-cl-fhd-1s-ma/index.m3u8?token=vAtd_yrRwoqwA2bqDvfS_Q&expires=1725086239' isLL={false} image={null} style={{ maxWidth: '100%', zIndex: 1}} width={window.innerWidth} height={window.innerHeight}/>
  
    </Suspense>
    </div>
  );
  
};

export default ShakaHLSLive;