// HorizontalList.js
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import './css/HorizontalList.css'; // 引入样式文件
import './css/App.css'; // 引入样式文件
import DetailPage from './DetailPage';
import VODDetailPage from './VODDetailPage';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-horizontal-scrolling-menu/dist/styles.css';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { debounce } from 'lodash';

import { toast } from 'react-toastify'; // 导入 toast 方法

import { setGlobalVariable, getGlobalVariable } from './manager/VerticalManager';
import { sethashMapByRowIndex, gethashMapByRowIndex, setIndexhashMapByRowIndex, getIndexhashMapByRowIndex } from './manager/HorizonManager';

const HorizontalList2 = ({ title, items, rowIndexModel, onRowIndexChange, listIndex, focusedVerticalIndex, setVerticalFocusedIndex }) => {


  const [focusedhorizontalIndex, setFocusedIndex] = useState(listIndex == getGlobalVariable() ? gethashMapByRowIndex().length > 2 ? gethashMapByRowIndex()[listIndex].targetIndex : 0 : 0); // 當前聚焦的物件索引
  //const [focusedhorizontalIndex, setFocusedIndex] = useState(rowIndexModel[listIndex] != null ? rowIndexModel[listIndex].targetIndex : 0); // 當前聚焦的物件索引
  const [visibleItems, setVisibleItems] = useState(5); // 可見物件的數量
  const [lastVisibleIndex, setLastVisibleIndex] = useState(listIndex == getGlobalVariable() ? gethashMapByRowIndex().length > 2 ? gethashMapByRowIndex()[listIndex].lastVisibleIndex : 5: 5); // 最後一個可見物件的索引
  const history = useHistory();

  const [keyDownStatus, setkeyDown] = useState(false);


  // 使用 useRef 来存储 poster 的引用
  const posterRef = useRef(items[focusedhorizontalIndex]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [enableSmoothScroll, setEnableSmoothScroll] = useState(false);
  const containerRef = useRef(null);
  
  useEffect(() => {
   
    if(gethashMapByRowIndex().length > 0){
      if(listIndex == getGlobalVariable()){
        console.log('HorizontalList2 hashMapByRowIndex[index].lastVisibleIndex : ' + gethashMapByRowIndex()[0].lastVisibleIndex);
      console.log('HorizontalList2 hashMapByRowIndex[index].targetIndex : ' + gethashMapByRowIndex()[0].targetIndex);
      }
      
    }
        
  }, []);

  // 更新 posterRef 的值
  useEffect(() => {
    if(listIndex == getGlobalVariable()){
      console.log("focusedVerticalIndex : " + focusedVerticalIndex);
      console.log("focusedhorizontalIndex : " + listIndex == getGlobalVariable() ? gethashMapByRowIndex().length > 2 ? gethashMapByRowIndex()[listIndex].targetIndex : 0 : 0);
    }
    
  }, [focusedVerticalIndex]);

  // 更新 posterRef 的值
  useEffect(() => {
    
    if(listIndex == getGlobalVariable()){
      console.log("focusedhorizontalIndex : " + focusedhorizontalIndex);
      posterRef.current = items[focusedhorizontalIndex];
    }
    
  }, [focusedhorizontalIndex, items]);


  const handleRightArrow = () => {
    setkeyDown(true);
    console.log("listIndex : " + listIndex + " focusedVerticalIndex : " + getGlobalVariable());
    console.log("focusedhorizontalIndex : " + focusedhorizontalIndex + " (items.length-1) : " + (items.length-1));
    if (focusedhorizontalIndex < (items.length-1)) {
      if(listIndex == getGlobalVariable()){
        console.log("ArrowRight");
        console.log('before focusedhorizontalIndex : ' + focusedhorizontalIndex );
        //console.log('focusedhorizontalIndex : ' + focusedhorizontalIndex + ', settings.slidesToShow - 1 : ' + (settings.slidesToShow - 1));
          
          
          
        setFocusedIndex(focusedhorizontalIndex + 1);
       console.log('after lastVisibleIndex : ' + lastVisibleIndex + ', focusedhorizontalIndex : ' + focusedhorizontalIndex + ', items.length : ' + items.length);
       if (focusedhorizontalIndex === lastVisibleIndex - 1) {
       
        if(listIndex == getGlobalVariable()){
          console.log('handleScroll((lastVisibleIndex-5) * (window.innerWidth * 0.25));');
          handleScroll((lastVisibleIndex-4) * (window.innerWidth * 0.14 + 45));
          
          
          setLastVisibleIndex(index => Math.min(items.length, index + 1));
          
        }
      }
        
      }
      
      
    }
  };

    // handleRightArrow 函数增加计数器
    const handleLeftArrow = () => {
      if (focusedhorizontalIndex > 0) {
                
        if(listIndex == getGlobalVariable()){
          
          setFocusedIndex(focusedhorizontalIndex - 1);
          console.log('focusedhorizontalIndex : ' + focusedhorizontalIndex + ', (lastVisibleIndex - 5) : ' + (lastVisibleIndex - 5));
          if (focusedhorizontalIndex <= (lastVisibleIndex - 3)) {
            console.log('handleScroll left');
            handleScroll((lastVisibleIndex-5) * (window.innerWidth * 0.14 + 45));
            setLastVisibleIndex(index => Math.min(items.length - 1, index - 1));
          }
        }
        
        

      }
    };


// 然后你就可以直接使用 debounce 了
const debouncedHandleRightArrow = debounce(handleRightArrow, 100);

// 然后你就可以直接使用 debounce 了
const debouncedHandleLeftArrow = debounce(handleLeftArrow, 100);

   useEffect(() => {
    const handleKeyDown = (event) => {
      //if (sliderRef.current)
      {
        requestAnimationFrame(() => {
          switch (event.key) {
            case 'ArrowLeft':
              console.log("ArrowLeft");
              debouncedHandleLeftArrow();
              
              break;
            case 'ArrowRight':
              console.log("ArrowRight");
              debouncedHandleRightArrow();
                
              //console.log("ArrowRight focusedhorizontalIndex : " + focusedhorizontalIndex + ", items.length - 1 : " + (items.length));
              
              
              break;
              case 'Enter':
                if(listIndex == getGlobalVariable()){
                  const poster = posterRef.current; // 获取最新的 poster
                  //console.log('posterRef.current : ' + posterRef.current.title);
                  history.push('/VODDetailPage2', { poster });
                }
                
                break;
                case '0':
                if(listIndex == getGlobalVariable()){
                  const poster = posterRef.current; // 获取最新的 poster
                  //console.log('posterRef.current : ' + posterRef.current.title);
                  history.push('/VODDetailPage4', { poster });
                }
                
                break;
                case '1':
                if(listIndex == getGlobalVariable()){
                  const poster = posterRef.current; // 获取最新的 poster
                  //console.log('posterRef.current : ' + posterRef.current.title);
                  history.push('/VODDetailPage3', { poster });
                }
                break;
            default:
              break;
          }
        });
        
      }
      
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedhorizontalIndex, lastVisibleIndex, items.length, keyDownStatus]); // 確保當 focusedIndex 改變時重新註冊鍵盤事件

  

  useEffect(() => {
    if(listIndex == getGlobalVariable()){
      console.log('listIndex : ' + listIndex + ", focusedVerticalIndex : " + focusedVerticalIndex);
      console.log('focusedhorizontalIndex : ' + focusedhorizontalIndex + ', lastVisibleIndex : ' +lastVisibleIndex );
      setkeyDown(false);
      onRowIndexChange(listIndex, lastVisibleIndex, focusedhorizontalIndex);

      if ((lastVisibleIndex-5) == focusedhorizontalIndex) {
        setLastVisibleIndex(index => Math.max(0, index - 1));
      }

      
    }
    

    

  }, [focusedhorizontalIndex]); // 确保在 focusedhorizontalIndex 变化时触发 useEffect

  useEffect(() => {
    if(listIndex == getGlobalVariable()){
      console.log('focusedhorizontalIndex : ' + focusedhorizontalIndex + ", lastVisibleIndex : " + lastVisibleIndex);
      //handleScroll((lastVisibleIndex-5) * (window.innerWidth * 0.16+45));
      handleScroll((lastVisibleIndex-5) * (window.innerWidth * 0.14 + 45));
      setEnableSmoothScroll(true);
      //toast("window.innerWidth : " + window.innerWidth, { autoClose: 2000 });
    }
  

  }, []); // 确保在 focusedhorizontalIndex 变化时触发 useEffect

// Function to handle scrolling when the button is clicked
const handleScroll = (scrollAmount) => {
  if(listIndex == getGlobalVariable()){
    
    // Update the state with the new scroll position
    setScrollPosition(scrollAmount);
    console.log(containerRef.current)
    // Access the container element and set its scrollLeft property
    //toast("scrollAmount : " + scrollAmount, { autoClose: 2000 });
    containerRef.current.scrollLeft = scrollAmount;
  }
  
};

  return (
    <div className="container">
      <h1 style={{ textAlign: 'left', width: '95%'}} >{title}</h1>
      <div ref={containerRef}
      style={{
        width: window.innerWidth * 1,
        overflowX: "scroll",
        scrollBehavior: enableSmoothScroll ? "smooth" : "auto",
      }}>
        
          <div className="content-box" >
          
          {items.map((poster, index) => (
            
  <div key={poster.productId} className="poster" style={{ display: 'flex', alignItems: 'center' }}>
    {index === (0) && (
      <div style={{ width: '80px', height: '100%', backgroundColor: 'black' }}></div>
    )}
    <div className={listIndex === getGlobalVariable() && focusedhorizontalIndex === index ? 'zoomable-object hovered' : 'zoomable-object'} style={{ width: window.innerWidth * 0.14 }}>
      <DetailPage item={poster} focus={listIndex === getGlobalVariable() && focusedhorizontalIndex === index ? true:false}/>
    </div>
    {index === (items.length - 1) && (
      <div style={{ width: '80px', height: '100%', backgroundColor: 'black' }}></div>
    )}
  </div>
))}
        </div>
      
      </div>
      
      
      </div>
  );


        

};

export default HorizontalList2;

