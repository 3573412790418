// DetailPage.js
import React, { useRef, useState, useEffect } from 'react';



import { useParams } from 'react-router-dom';
import './css/HorizontalList.css'; // 引入样式文件
import './css/ZoomableObject.css';
import times from "lodash/times";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import 'react-lazy-load-image-component/src/effects/blur.css';
const DetailPage = ({ item, focus}) => {
    //const { id } = useParams();
    const [isHovered, setIsHovered, focusedElement, setFocusedElement] = useState(false);
    const titleRef = useRef(null);
    const [isOverflow, setIsOverflow] = useState(false);

    const [displayText, setDisplayText] = useState(item.title); // 使用状态管理显示的文本

    const [animationIteration, setAnimationIteration] = useState(0);

    useEffect(() => {
      // 创建一个用于计算文本长度的临时画布
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // 设置字体样式
      context.font = '32px Arial';
      // 计算文本的宽度
      const textWidth = context.measureText(item.title).width;
      setIsOverflow(textWidth > window.innerWidth * 0.14);
      const fullText = item.title;
      const maxChars = Math.floor((window.innerWidth * 0.14) / 34); // 假设每个字符平均宽度为10px
      const newText = fullText.substr(0, maxChars) + '......'; // 截取文本并添加省略号
      

      if(focus){
        if (animationIteration >= 3 && isOverflow) {
          const fullText = item.title;
    
          const maxChars = Math.floor((window.innerWidth * 0.14) / 34); // 假设每个字符平均宽度为10px
          const newText = fullText.substr(0, maxChars) + '......'; // 截取文本并添加省略号
          setDisplayText(newText); // 更新状态以显示省略号版本的文本
        }
      }else{
        setDisplayText(textWidth > window.innerWidth * 0.14 ? newText:fullText); // 更新状态以显示省略号版本的文本
        setAnimationIteration(0);
      }

  }, [displayText, animationIteration ]);

    const handleMouseEnter = () => {
        //setFocus();
        setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };



    const handleFocus = (element) => {
      console.log('handleFocus');
      setDisplayText(item.title);
      setFocusedElement(element);
    };


    const marginRight = "color: #FFFFFF";

  // 动画结束后的处理
  useEffect(() => {
    
    
  }, [animationIteration, isOverflow, item.title, focus]);

  // 使用useEffect来监视focus状态的变化
  useEffect(() => {
    if (focus && animationIteration < 3) {
      setDisplayText(item.title);
    }
  }, [focus]);

  const handleIndexChange = () => {
    console.log('handleIndexChange');
  
    requestAnimationFrame(() => {
      setAnimationIteration(prev => {
        const newIterationCount = prev + 1;
        console.log('Animation iteration count:', newIterationCount);
  
        if (newIterationCount === 3) {
          
          
        }else{
          setDisplayText(item.title);
        }
  
        return newIterationCount;
      });
    });

  };

  const handleAnimationEnd = () => {
    const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // 设置字体样式
      context.font = '32px Arial';
      // 计算文本的宽度
      const textWidth = context.measureText(item.title).width;
      const fullText = item.title;
      const maxChars = Math.floor((window.innerWidth * 0.14) / 34); // 假设每个字符平均宽度为10px
      const newText = fullText.substr(0, maxChars) + '......'; // 截取文本并添加省略号
      setDisplayText(textWidth > window.innerWidth * 0.14 ? newText:fullText); // 更新状态以显示省略号版本的文本
      setAnimationIteration(0);
  };

    return (
        <div 
      // className={`zoomable-object ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div className="list-item" width={window.innerWidth * 0.14}>
      {/* <ImageWithPlaceholder alt={item.text} src={'https://apl-hamivideo.cdn.hinet.net/HamiVideo/getHamiVideoImg.php?imageId='+ item.imageId}  placeholder={'https://apl-hamivideo.cdn.hinet.net/HamiVideo/getHamiVideoImg.php?imageId='+ item.imageId} style={{ width: window.innerWidth * 0.14, marginBottom: '0px' }}/> */}
      <LazyLoadImage
        alt={item.text}
        loading="eager"
        src={'https://apl-hamivideo.cdn.hinet.net/HamiVideo/getHamiVideoImg.php?imageId='+ item.imageId} 
       
        style={{ width: window.innerWidth * 0.14, marginBottom: '0px' }}
      />
          
          
       
          
          <div className="marquee-container" style={{ width: window.innerWidth * 0.14 }}>
            <div className={focus && isOverflow ? "marquee" : ''} style={{ width: window.innerWidth * 0.14 }}
            ref={titleRef}
            onAnimationEnd={handleAnimationEnd}
            onAnimationIteration={() => 
              handleIndexChange()}>
              <p style={{ color: '#FFFFFF', textDecoration: 'none' ,fontSize: '32px', margin: '0px'}}>{displayText}</p>
            </div>
        </div>
      </div>



    </div>
    );
    
  
  };
  
  
  export default DetailPage;

  /*
<div className={isOverflow?"container-title":''} width={window.innerWidth * 0.18}>
            <p style={{ color: '#FFFFFF', textDecoration: 'none' ,fontSize: '32px' }}>{item.title}</p>
          </div>
  */