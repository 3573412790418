// manager.js
const hashMapByRowIndex = [];

function sethashMapByRowIndex(value) {
    console.log('sethashMapByRowIndex');
    if (Array.isArray(value)) {
        hashMapByRowIndex.push(...value);
    }
}

function gethashMapByRowIndex() {
    return hashMapByRowIndex;
}

function setIndexhashMapByRowIndex(index, value) {
    if (index >= 0 && index < hashMapByRowIndex.length) {
        hashMapByRowIndex[index].lastVisibleIndex = value.lastVisibleIndex;
        hashMapByRowIndex[index].targetIndex = value.targetIndex;
        console.log('HorizonManager setIndexhashMapByRowIndex Done');
        console.log('HorizonManager hashMapByRowIndex[index].lastVisibleIndex : ' + hashMapByRowIndex[index].lastVisibleIndex);
        console.log('HorizonManager hashMapByRowIndex[index].targetIndex : ' + hashMapByRowIndex[index].targetIndex);
    }
}

function getIndexhashMapByRowIndex(index){
    if (index >= 0 && index < hashMapByRowIndex.length) {
        return hashMapByRowIndex[index];
    } else {
        console.warn('Index out of bounds');
    }
}

export { sethashMapByRowIndex, gethashMapByRowIndex, setIndexhashMapByRowIndex, getIndexhashMapByRowIndex };
