import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import dashjs from "dashjs";

const DashPlayer = forwardRef(({ url, licenseUrl, style  }, ref) => { 
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    adjustPlaybackTime: (seconds) => {
      if (videoRef.current) {
        console.log("adjustPlaybackTime");
        let newTime = videoRef.current.currentTime + seconds;
        videoRef.current.currentTime = Math.max(0, newTime); // 防止时间小于0
      }
    },
    changePlaybackRate: (rate) => {
      console.log("rate : " + rate);
      // videoElement.playbackRate = rate;
      // videoElement.playbackRate.pause();
      // videoElement.playbackRate.play();
      
    },
    getPlaybackRate: () => {
      return videoRef.current.playbackRate;
      //return videoRef.current ? videoRef.current.playbackRate : 1;
    },
  }));

  const pseudoFullscreenStyle = {
    position: 'absolute', // 或 'absolute'
    top: 0,
    left: 0,
    // width: '100%',
    // height: '100%',
    zIndex: 9999,
  };

  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      playerRef.current = dashjs.MediaPlayer().create();
      playerRef.current.initialize(videoRef.current, url, true);
      console.log("url : " + url);
      console.log("licenseUrl : " + licenseUrl);
      if (licenseUrl) {
        // 设置 Widevine DRM 许可证信息
        playerRef.current.setProtectionData({
          'com.widevine.alpha': {
            serverURL: licenseUrl
          }
        });
      }
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
    };
  }, [url, licenseUrl]);


  // useEffect(() => {
  //   const player = dashjs.MediaPlayer().create();
    
  //   // 设置 Widevine DRM 许可证信息
  //   player.setProtectionData({
  //     'com.widevine.alpha': {
  //       serverURL: licenseUrl
  //     }
  //   });

  //   player.initialize(videoRef.current, url, true); // 第三个参数为 true 表示启用 DRM

  //   return () => {
  //     player.reset();
  //   };
  // }, [url, licenseUrl]);

  return (
    <div class="dash-video-player ">
      <div class="videoContainer" id="videoContainer">
        <video
          slot="media"
          controls={false}
          ref={videoRef}
          style={{ width: "100%" }}
          preload="auto"
          autoPlay="true"
        />
        
      </div>
    </div>
  );
})


export default DashPlayer;