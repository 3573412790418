import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import './css/App.css'; // 引入样式文件
import { setplayer, getplayer } from './manager/PlayerManager';

const ChoosePage = () => {
    const history = useHistory();
    
    const buttonsRef = useRef([]);
  
    useEffect(() => {

        // 設置初始焦點到第一個按鈕
        if (buttonsRef.current[0]) {
            buttonsRef.current[0].focus();
        }

        const handleKeyDown = (e) => {
          const focusedIndex = buttonsRef.current.findIndex(btn => btn === document.activeElement);
          if (e.key === 'ArrowRight' && (focusedIndex == 0 || focusedIndex == 1 || focusedIndex == 3)) {
              console.log('ArrowRight');
              if(focusedIndex == 0){
                buttonsRef.current[1].focus();
              }else if(focusedIndex == 1){
                buttonsRef.current[2].focus();
              }else if(focusedIndex == 3){
                buttonsRef.current[4].focus();
              }
            
          } else if (e.key === 'ArrowLeft' && focusedIndex > 0) {
              console.log('ArrowLeft');
            buttonsRef.current[focusedIndex - 1].focus();
          } else if(e.key === 'ArrowDown') {
            switch(focusedIndex){
              case 0:
                buttonsRef.current[3].focus();
              break;
              case 1:
                buttonsRef.current[3].focus();
              break;
              case 2:
                buttonsRef.current[4].focus();
              break;
            }
          } else if(e.key === 'ArrowUp') {
            switch(focusedIndex){
              case 3:
                buttonsRef.current[0].focus();
              break;
              case 4:
                buttonsRef.current[2].focus();
              break;
            }
          }
        };
  
      window.addEventListener('keydown', handleKeyDown);
  
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);
  
    const handleClick = (pageIndex) => {
        console.log("pageIndex : " + pageIndex);
        switch(pageIndex){
          case 1:
            setplayer(0);
            history.push('/VODDetailPage5', null);
          break;
          case 2:
              setplayer(0);
              //history.push('/VODDetailPage4', { isShaka: false });
              history.push('/VODDetailPage3', null);
            break;
            case 3:
              setplayer(0);
              history.push('/VODDetailPage4', { isShaka: false });
            break;
            case 4:
              setplayer(0);
                console.log("pageIndex : " + pageIndex);
                history.push('/App');
            break;
            case 5:
              setplayer(1);
              history.push('/VODDetailPage4', { isShaka: false });
            break;
            case 6:
              setplayer(1);
              history.push('/ShakaHLSLLLive', null);
            break;
            case 7:
              setplayer(1);
              history.push('/VODDetailPage4', null);
            break;
            case 8:
              setplayer(0);
              history.push('/App');
            break;
        }
      //history.push(`/page${pageIndex}.js`);
    };
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ textAlign: 'left', width: '95%',  color: 'white'}} >頻道</h1>
        <div className="button-container" style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          {[1, 2, 3].map((number, index) => (
            <button
              key={number}
              ref={el => buttonsRef.current[index] = el}
              onClick={() => handleClick(number)}
              className="focus-button"
              style={{ fontSize: '20px', flex: 1 }} 
            >
              {/*               {number === 4 && "Clear hls"}
          {number === 3 && "Widevine VOD"}
          {number === 2 && "Live LL-hls"}
          {number === 1 && "Live hls"} */}
          {number === 3 && "DASH(H.264) + Widevine"}
          {number === 2 && "HLS(HEVC) + LL"}
          {number === 1 && "HLS(H.264) + LL"}
            </button>
          ))}
        </div>
        <h1 style={{ textAlign: 'left', width: '95%',  color: 'white'}} >VOD</h1>
        <div className="button-container" style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          {[5, 8].map((number, index) => (
            <button
              key={number}
              ref={el => buttonsRef.current[index + 3] = el}
              onClick={() => handleClick(number)}
              className="focus-button"
              style={{ fontSize: '20px', flex: 1 }} 
            >
              {/*               {number === 8 && "Clear hls"}
          {number === 7 && "Widevine VOD"}
          {number === 6 && "Live LL-hls"}
          {number === 5 && "Live hls"} */}
              {number === 8 && "Clear HLS(H.264)"}
          {number === 5 && "DASH(H.264) + Widevine"}
            </button>
          ))}
        </div>
      </div>
    );
  };



export default ChoosePage;