import React, { useState, useEffect } from 'react';

function ImageWithPlaceholder({ src, placeholder }) {
  const [imageSrc, setImageSrc] = useState(placeholder);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
      setLoading(false);
    };
  }, [src]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {loading && <div>Loading...</div>}
      <img src={imageSrc} alt="" style={{ width: '100%', height: '100%', display: loading ? 'none' : 'block' }} />
    </div>
  );
}
export default ImageWithPlaceholder;