import React, { useEffect, Suspense, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { toast } from 'react-toastify'; // 导入 toast 方法
import HLSPlayer from './player/HLSPlayer'
import { setplayer, getplayer } from './manager/PlayerManager';
//import VideoPlayer from './player/VideoJSPlayer';

const LazyShakaPlayer = React.lazy(() => import('./player/VideoPlayer'));




const VODDetailPage2 = () => {
   
   const location = useLocation();
   const { poster: posterFromLink } = location.state || {};

  
   const posterFromLocation = useLocation().state?.poster;
   const poster = posterFromLink || posterFromLocation;

   const history = useHistory();
   const videoPlayerRef = useRef();

   // 假设 getplayer 方法返回 0 或 1
  const playerType = getplayer();

  useEffect(() => {
    console.log('VODDetailPage2');
    const handleKeyDown = (event) => {
      console.log('handleKeyDown : ' + event.key);
     
      if(event.key === 'Backspace' || event.key === 'XF86Back'){
        history.goBack(); // 调用 goBack 方法返回到上一页

      }
      else if(event.key === 'ArrowLeft'){
        if (videoPlayerRef.current && videoPlayerRef.current.adjustPlaybackTime) {
          videoPlayerRef.current.adjustPlaybackTime(-5);
          toast("快轉5秒", { autoClose: 1000 });
        }

      }else if(event.key === 'ArrowRight'){
        if (videoPlayerRef.current && videoPlayerRef.current.adjustPlaybackTime) {
          toast("快轉5秒", { autoClose: 1000 });
        videoPlayerRef.current.adjustPlaybackTime(5); // 向後調整2秒
        }
        
      }else if(event.key === 'ArrowUp'){
        const playbackRate = videoPlayerRef.current.getPlaybackRate()
        console.log('当前播放速率:', playbackRate);
        videoPlayerRef.current.changePlaybackRate(videoPlayerRef.current.getPlaybackRate() + 0.2); // 加倍播放速度
        //history.push('/VODDetailPage4', null);

      }else if(event.key === 'ArrowDown'){
        //history.push('/VODDetailPage3', null);
        videoPlayerRef.current.changePlaybackRate(videoPlayerRef.current.getPlaybackRate() - 0.2); // 加倍播放速度
      }
      
      //history.goBack(); // 调用 goBack 方法返回到上一页
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // 確保當 focusedIndex 改變時重新註冊鍵盤事件


  const videoJsOptions = { // 定义播放器的配置选项
    autoplay: true,
    controls: false,
    preload: 'auto',
    language: 'zh-TW',
    width: 854,
    height: 480,
    fluid: 'true',
    playbackRates: [0.5, 0.75, 1, 1.5, 2], // 倍速数组
    sources: [{
      src: poster.previewUrl
    }]
  };


if (!poster) {
  return <div>未找到相關資料</div>;
}


return (
  <div style={{width:'100vw', height:'100vh'}}>
     {/* 在這裡調用 setUrlAndPlay */}
     <HLSPlayer
            ref={videoPlayerRef}
            url={poster.previewUrl}
            style={{ width: '100%', height: '100%', zIndex: 1 }}
            isVOD={true}
          /></div>
  
);
  
  
};

export default VODDetailPage2;