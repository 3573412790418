import React, { useEffect, Suspense, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import VideoPlayer from './player/VideoJSPlayerDRM';
import HLSPlayer from './player/HLSPlayer'
import DashPlayer from './player/DashPlayer';
import { toast } from 'react-toastify'; // 导入 toast 方法
import { setplayer, getplayer } from './manager/PlayerManager';

//import VideoPlayer from './player/VideoPlayer';
const VODDetailPage4 = () => {
   

  const history = useHistory();
  const LazyShakaPlayer = React.lazy(() => import('./player/VideoPlayer'));
  const videoPlayerRef = useRef();

  const dashStreamUrl1 = 'https://wowzatest-hieventtrial2.cdn.hinet.net/vod_tl_proxy-wv/_definst_/smil:zztest/bbb_sunflower_10min_v2/bbb_sunflower_10min.smil/manifest.mpd';
  const licenseUrl1 = 'https://tlmrlab-hamivideo2.cdn.hinet.net/getLicense?sessionId=a6aed39b0742b445352552cf0cbe6853&deviceIp=212.82.115.16&region=chtn&chsm=ab1bda49a4382830d759c8184271e80b';

  const dashStreamUrl = 'https://drmteste-hamivideo.cdn.hinet.net/live/pool/ll-test-2s/da-tlwv-fhd-2s-ma/ll-test-2s.mpd';
  const licenseUrl = 'https://tlmrlab-hamivideo2.cdn.hinet.net/getLicense';

 // 假设 getplayer 方法返回 0 或 1
 const playerType = getplayer();

  useEffect(() => {
  
    //SetUrlAndPlay('https://drmteste-hamivideo.cdn.hinet.net/live/pool/ll-test-2s/da-tlwv-fhd-2s-ma/ll-test-2s.mpd' , 'https://tlmrlab-hamivideo2.cdn.hinet.net/v1/wv/getLicense?sessionId=5566&deviceOs=ios_17.0&chsm=1df20742c0d6d61557af1de3e0590636&token=eyJhbGciOiJSU0ExXzUiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2In0.DMXXZzmWaNK25kg_pSDcIbmEZMDqcv62vB_0gSj7wB15uQtL9MoPu2P7gA8WJ1JycmaskycSF8R92QUnmhl3ds2_bL746a6rckyy9f75aBfUk8_QdoA_9uZpuPPEQffawvyHezmsRem1Zywu1s0d_zbE-paiS8AnSlJWvq3felDpviWKCoHaeQ29itN3uQ2hAhwOKhwOl03kfJZdgwVerXrbK7VmGO9D5EQB_0JA703JEoYebQpc4GTxkJNG0WHh__UWnwAm6yaXYGsdi3tGrRx8EZQelPcTOxDYyuHRJN-qw-AR60ezjON6SsWk12VROEHN5rKe0E54ymewPPt4lA.x_090YkgVMMraS9HKJJCQA.oywl5o7ji6dMMA1117Q2htiPs9ydPcDirXW26pr08vuxHaUqOQR6OSdDTsA91r_FQizlF7v9pCBxwDrSS9MdZCJglGrlRTkun9HlZhKoYFXWR4aDMgfC-5pBb-SG1XHomPFFBNxA-hKT2ZHwzGnwmBl5IdVgS8tStBT5cq4ops5mad4tI5fgHksusBfyejFabGwnZEPdY4iKkZP9l1MSagw7KFrKNbXM8biiwkfglEeXOx0jph2xK3dpAIGXDQaycMddGOmMHORf8x4-4ccBYvWoEx-OBUK3MLxXjXm6rnpa5P6u8aM-8HU3AcobDTMhfypc8iswnaPKUISgLw34U84UMM8MImMWRpYjjuKZZK9n05MT64obqxeAX_gKjZhvtPxEQhSWAdI09E9Y3RMPNmXSdFs4nSvQmr1qGHoi28yHXvfUhQ7SOcjhKdRVOTJ8PaYf_sDXlcwmcY61IDVGKptIwgfPUnFw9X8f-7gr8v9RxVYulyYk66BOe-E5IxjQXpTfWbVaxY6rpj1wXuwNPdSUcVLAZ4wWmJlhlQXCwgCUxklFGlB6Tv2U69u6hw2cKTF75WATob7rpLHmeBrK4th0xXO0L7ltcZ9ecNk8hAilUOQM-b5kb_F5nwZ35VPeJuPxstgyzbzT7cdUcrw43_qANpS0LKUQCzQzi_Q2iAK8duYQVRlFJG-HcL4jgv6l3xy4a-BERtxLY4eebtty4Xjp6FT_gZ3ue4RoV9kVE4KHzL7Lyk9IjMYftd8XYTHm0effPhcM1wzk7zP3RtTtIa9ECYYMpMj_PA8Aii4tQwobde-KW7VvubBRX2fXVaYd5tWk4Fu5B1FFnJ4qJzStZ_75tuBZgq67BGfVMhhKraMH6DPl_jUSRlEwco3bTw9jt9yJaUIj6bSRFj5mOZDymlNj2cvOnPaHp0Wfi4GkfeSbjx9JcI96ylKjHwKr-ioEaBSeGsMvl_Kc9-7wcPAlMcpIn-NWGszAp1irqThRSu8raIVaq-Y1rUsI3a6xzgvsGhuKCDMo1Xem31vO0uttwcsAAOTuN3lzPGBGlMxVB5U7GREAlTL4qJ6Y5b-kX1yeYnyqsP9XrFTo3vDzWkU4zg.YBxNzoJlLOWi5eVQY3BStw');
    return () => {
      // Cleanup code here if needed
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log('handleKeyDown : ' + event.key);
      if(event.key === 'Backspace' || event.key === 'XF86Back'){
        history.goBack(); // 调用 goBack 方法返回到上一页

      }
      else if(event.key === 'ArrowLeft'){
        if (videoPlayerRef.current && videoPlayerRef.current.adjustPlaybackTime) {
          videoPlayerRef.current.adjustPlaybackTime(-5);
          toast("快轉5秒", { autoClose: 1000 });
        }
       
      }else if(event.key === 'ArrowRight'){
        if (videoPlayerRef.current && videoPlayerRef.current.adjustPlaybackTime) {
          videoPlayerRef.current.adjustPlaybackTime(5);
          toast("快轉5秒", { autoClose: 1000 });
        }
      }else if(event.key === 'ArrowUp'){
        const playbackRate = videoPlayerRef.current.getPlaybackRate()
        console.log('当前播放速率:', playbackRate);
        videoPlayerRef.current.changePlaybackRate(videoPlayerRef.current.getPlaybackRate() + 0.2); // 加倍播放速度
        //history.push('/VODDetailPage4', null);

      }else if(event.key === 'ArrowDown'){
        //history.push('/VODDetailPage3', null);
        videoPlayerRef.current.changePlaybackRate(videoPlayerRef.current.getPlaybackRate() - 0.2); // 加倍播放速度
      }
      
      
      //history.goBack(); // 调用 goBack 方法返回到上一页
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // 確保當 focusedIndex 改變時重新註冊鍵盤事件

  const videoJsOptions = { // 定义播放器的配置选项
    autoplay: true,
    controls: false,
    width: window.innerWidth,
    height: window.innerHeight,
 
  };


  return (
    <div>
{playerType === 0 ? (
            <DashPlayer ref={videoPlayerRef} options={videoJsOptions} url={dashStreamUrl} licenseUrl={licenseUrl} />
            // <VideoPlayer ref={videoPlayerRef} options={videoJsOptions} src={dashStreamUrl} licenseUrl={licenseUrl} />
          ) : (
            <DashPlayer ref={videoPlayerRef} options={videoJsOptions} url={dashStreamUrl1} licenseUrl={licenseUrl1} />
            // <Suspense fallback={<div>Loading...</div>}>
            //   <LazyShakaPlayer ref={videoPlayerRef} src={dashStreamUrl} licenseUrl={licenseUrl} image={null} style={{ maxWidth: '100%', zIndex: 1}} width={window.innerWidth} height={window.innerHeight}/>
            // </Suspense>
          )}
    </div>
  );
  
};

export default VODDetailPage4;

// { <HLSPlayer url={"https://wowzatest-hieventtrial2.cdn.hinet.net/vod_tl_proxy-wv/_definst_/smil:zztest/bbb_sunflower_10min_v2/bbb_sunflower_10min.smil/manifest.mpd"} isLL={true} style={{ maxWidth: '100%', zIndex: 1}} width={window.innerWidth} height={window.innerHeight}></HLSPlayer> */}
//     { <VideoPlayer options={videoJsOptions} src="https://wowzatest-hieventtrial2.cdn.hinet.net/vod_tl_proxy-wv/_definst_/smil:zztest/bbb_sunflower_10min_v2/bbb_sunflower_10min.smil/manifest.mpd" licenseUrl="https://tlmrlab-hamivideo2.cdn.hinet.net/getLicense?sessionId=a6aed39b0742b445352552cf0cbe6853&deviceIp=212.82.115.16&region=chtn&chsm=ab1bda49a4382830d759c8184271e80b" /> }

//     { <Suspense >
      
//       <LazyShakaPlayer ref={videoPlayerRef} src='https://wowzatest-hieventtrial2.cdn.hinet.net/vod_tl_proxy-wv/_definst_/smil:zztest/bbb_sunflower_10min_v2/bbb_sunflower_10min.smil/manifest.mpd' licenseUrl="https://tlmrlab-hamivideo2.cdn.hinet.net/getLicense?sessionId=a6aed39b0742b445352552cf0cbe6853&deviceIp=212.82.115.16&region=chtn&chsm=ab1bda49a4382830d759c8184271e80b" image={null} style={{ maxWidth: '100%', zIndex: 1}} width={window.innerWidth} height={window.innerHeight}/>
       
//       </Suspense> }
