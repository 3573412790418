// manager.js
let player = 0;

function setplayer(value) {
    player = value;
}

function getplayer() {
    return player;
}

export { setplayer, getplayer };
